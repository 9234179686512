<template>
  <v-card min-height="100%" min-width="100%" color="grey lighten-2" tile>
    <v-card-title> Meine Anmeldungen </v-card-title>
    <v-card class="ma-5" tile>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Anmeldezeitpunkt</th>
              <th class="text-left">Plattform</th>
              <th class="text-left">Browser</th>
              <th class="text-left">Land</th>
              <th class="text-left">Stadt</th>
              <th class="text-center">Löschen</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="s in sessions" :key="s.int_id">
              <td>{{ s.created }}</td>
              <td>{{ s.platform }}</td>
              <td>{{ s.browser }}</td>
              <td>{{ s.country }}</td>
              <td>{{ s.city }}</td>
              <td class="text-center">
                <v-btn
                  fab
                  x-small
                  text
                  color="primary"
                  @click="delete_session(s.int_id)"
                  ><v-icon>mdi-delete</v-icon></v-btn
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </v-card>
</template>

<script>
export default {
  created() {
    this.get_all_sessions();
  },
  data: () => ({
    sessions: [],
  }),
  methods: {
    async get_all_sessions() {
      let { data } = await this.$api.session.get_all();
      this.sessions = data.sessions;
    },
    async delete_session(int_id) {
      this.$root.$confirm
        .open(
          "Bestätigen",
          "Bist du dir sicher, dass diese Session ausgeloggt werden soll?",
          {
            color: "error",
          }
        )
        .then((confirm) => {
          if (confirm) {
            this.$api.session
              .delete({ int_id })
              .then(() => this.get_all_sessions());
          }
        });
    },
  },
};
</script>
