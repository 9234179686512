<template>
  <v-card min-height="100%" min-width="100%" color="grey lighten-2" tile>
    <v-card-title>
      Aktuelle Positionen aller Mitarbeiter
      <v-spacer></v-spacer>
      <v-btn :loading="loading" @click="download" tile outlined>
        <v-icon>mdi-download</v-icon> Download
      </v-btn>
    </v-card-title>
    <v-card class="ma-5" tile v-for="(user, u) in users" :key="u">
      <v-card-title>
        <span class="headline">{{ user.username }}s Portfolio</span>
      </v-card-title>
      <v-card-text v-if="user.portfolio.length === 0"
        >{{ user.username }}s Portfolio ist leer.</v-card-text
      >
      <v-card-text v-if="user.portfolio.length > 0">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">ISIN</th>
                <th class="text-left">Name</th>
                <th class="text-left">Instrument</th>
                <th class="text-left">Long / Short</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(position, i) in user.portfolio" :key="i">
                <td>{{ position.isin }}</td>
                <td>{{ position.name }}</td>
                <td>{{ position.instrument }}</td>
                <td>{{ position.direction }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
export default {
  created() {
    this.get_all_transactions();
  },
  data: () => ({
    users: [],
    loading: false,
  }),
  methods: {
    async get_all_transactions() {
      let { data } = await this.$api.transaction.get_all_portfolios();
      this.users = data.users;
    },
    async download() {
      this.loading = true;
      let response = await this.$api.transaction.download_all_portfolios();
      const blob = new Blob([response.data], { type: "text/plain" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "Portfolios.txt";
      link.click();
      URL.revokeObjectURL(link.href);
      this.$store.commit("snackbar/setSnackbarData", {
        message: "Download abgeschlossen",
        color: "success",
        timeout: 6000,
      });
      this.loading = false;
    },
  },
};
</script>
