<template>
  <v-card min-height="100%" min-width="100%" color="grey lighten-2" tile>
    <v-card-title>Tools</v-card-title>
    <v-row no-gutters>
      <v-col
        cols="12"
        sm="6"
        lg="4"
        v-for="(card, c) in cards"
        :key="c"
        class="my-2 d-flex"
        style="flex-direction: column"
      >
        <v-card
          class="mx-5 flex-grow-1"
          tile
          @click="$router.push({ name: card.route })"
        >
          <v-card-title
            ><v-icon class="pr-3">{{ card.icon }}</v-icon> {{ card.title }}
          </v-card-title>
          <v-card-subtitle>{{ card.description }}</v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  computed: {
    cards() {
      let cards_ = [];
      if (this.$store.getters["user/role"].includes("Admin")) {
        cards_.push({
          title: "Admin-Panel",
          route: "AdminPanel",
          icon: "mdi-account-multiple",
          description:
            "Füge neue Mitarbeiter hinzu, ändere Mitarbeiterdaten, vergib Rechte.",
        });
        cards_.push({
          title: "Blacklist-Verstöße",
          route: "BlacklistViolations",
          icon: "mdi-flash",
          description:
            "Schaue nach, wann ein Mitarbeiter gegen die Blacklist verstoßen hat.",
        });
        cards_.push({
          title: "Sperrfristen-Check",
          route: "ArticleCheck",
          icon: "mdi-timeline-clock",
          description:
            "Schaue nach, wann ein Mitarbeiter gegen die Sperrfirst verstoßen hat.",
        });
      }
      if (this.$store.getters["user/role"].indexOf("Mitarbeiter") > -1) {
        cards_.push({
          title: "Transaktionen",
          route: "TransactionPanel",
          icon: "mdi-cash-check",
          description:
            "Füge neue Transaktionen hinzu und schau dir deine aktuellen Positionen an.",
        });
        cards_.push({
          title: "Konten",
          route: "Accounts",
          icon: "mdi-bank",
          description: "Verwalte deine Depots und Vermögensverwaltungsmandate",
        });
        cards_.push({
          title: "Blacklist",
          route: "Blacklist",
          icon: "mdi-cancel",
          description:
            "Schaue an, welche Aktien derzeitig nicht gehandelt werden dürfen.",
        });
        cards_.push({
          title: "Mitarbeiter-Positionen",
          route: "AllPortfolios",
          icon: "mdi-human-queue",
          description:
            "Schaue an, welche Aktien deine Kollegen derzeitig im Portfolio haben.",
        });
        cards_.push({
          title: "Mitarbeiter-Regeln",
          route: "Rules",
          icon: "mdi-file-sign",
          description: "Regeln für TraderFox-Mitarbeiter.",
        });
      }
      if (this.$store.getters["user/role"].indexOf("Index") > -1) {
        cards_.push({
          title: "Index-Umschichtungen",
          route: "IndexPanel",
          icon: "mdi-autorenew",
          description: "Füge neue Index-Umschichtungen hinzu.",
        });
      }
      cards_.push({
        title: "Sessions",
        route: "SessionPanel",
        icon: "mdi-account-key",
        description:
          "Schaue alle deine eingeloggten Geräte an und manage deren Anmeldungen.",
      });
      return cards_;
    },
  },
  methods: {},
};
</script>
