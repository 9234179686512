import connection from "./connection";

const resource = "/api/blacklist_portfolio";
export default {
  get_all() {
    return connection.get(`${resource}`);
  },
  create(payload) {
    return connection.post(`${resource}`, payload);
  },
  delete_one(payload) {
    return connection.delete(`${resource}/${payload.id}`);
  },
};
