<template>
  <v-card min-height="100%" min-width="100%" color="grey lighten-2" tile>
    <v-card-title> Meine Depots</v-card-title>
    <v-card class="ma-5" tile>
      <v-card-title>
        <span class="headline">Meine Trading-Konten</span>
      </v-card-title>
      <v-card-text>
        <v-chip
          v-for="(account, t) in trading_accounts"
          :key="t"
          close
          class="mr-1"
          color="blue darken-1"
          text-color="white"
          @click:close="delete_trading_account(account.trading_account_id)"
        >
          {{ account.name }}
        </v-chip>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="success ma-5"
          data-cy="add_trading_account"
          fab
          text
          small
          @click="
            dialog_trading_account = !dialog_trading_account;
            trading_account_name = '';
          "
          ><v-icon>mdi-plus</v-icon></v-btn
        >
      </v-card-actions>
      <v-dialog v-model="dialog_trading_account" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Neues Trading-Konto anlegen</span>
          </v-card-title>
          <v-card-text>
            <v-text-field
              data-cy="text_field_trading_account"
              label="Name des Trading-Kontos"
              v-model="trading_account_name"
              required
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              data-cy="save_trading_account"
              color="success"
              text
              @click="create_trading_account()"
            >
              Speichern
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>

    <v-card class="ma-5" tile>
      <v-card-title>
        <span class="headline">Meine Vermögensverwaltungsmandate</span>
      </v-card-title>
      <v-card-text>
        <v-chip
          v-for="(account, t) in managed_accounts"
          :key="t"
          close
          class="mr-1"
          color="blue darken-1"
          text-color="white"
          @click="
            managed_account_update = account;
            dialog_managed_account_modify = true;
          "
          @click:close="delete_managed_account(account.managed_account_id)"
        >
          {{ account.name }}
        </v-chip>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          data-cy="add_managed_account"
          class="success ma-5"
          fab
          text
          small
          @click="
            dialog_managed_account = !dialog_managed_account;
            managed_account_name = '';
          "
          ><v-icon>mdi-plus</v-icon></v-btn
        >
      </v-card-actions>
      <v-dialog v-model="dialog_managed_account" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline"
              >Neues Vermögensverwaltungsmandat anlegen</span
            >
          </v-card-title>
          <v-card-text>
            <v-text-field
              data-cy="text_field_managed_account"
              label="Name des verwaltenden Bank"
              v-model="managed_account_name"
              required
            ></v-text-field>
            <v-textarea
              data-cy="text_area_managed_account"
              label="Beschreibung"
              v-model="managed_account_description"
              required
            ></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              data-cy="save_managed_account"
              color="success"
              text
              @click="create_managed_account()"
            >
              Speichern
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-if="managed_account_update !== null"
        v-model="dialog_managed_account_modify"
        max-width="600px"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Vermögensverwaltungsmandat</span>
          </v-card-title>
          <v-card-text>
            <v-text-field
              data-cy="text_field_managed_account_modify"
              label="Name des verwaltenden Bank"
              v-model="managed_account_update.name"
              required
            ></v-text-field>
            <v-textarea
              data-cy="text_area_managed_account_modify"
              label="Beschreibung"
              v-model="managed_account_update.description"
              required
            ></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              data-cy="save_managed_account_modify"
              color="success"
              text
              @click="update_managed_account()"
            >
              Speichern
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-card>
</template>

<script>
export default {
  created() {
    this.get_trading_accounts();
    this.get_managed_accounts();
  },
  data: () => ({
    managed_accounts: [],
    managed_account_name: "",
    managed_account_description: "",
    dialog_managed_account: false,
    managed_account_update: null,
    dialog_managed_account_modify: false,
    trading_accounts: [],
    trading_account_name: "",
    dialog_trading_account: false,
  }),
  methods: {
    async get_trading_accounts() {
      let { data } = await this.$api.trading_account.get_all();
      this.trading_accounts = data.trading_accounts;
    },
    async delete_trading_account(trading_account_id) {
      await this.$api.trading_account.delete({ trading_account_id });
      await this.get_trading_accounts();
    },
    async create_trading_account() {
      await this.$api.trading_account.create({
        trading_account_name: this.trading_account_name,
      });
      await this.get_trading_accounts();
      this.dialog_trading_account = false;
    },
    async get_managed_accounts() {
      let { data } = await this.$api.managed_account.get_all();
      this.managed_accounts = data.managed_accounts;
    },
    async delete_managed_account(managed_account_id) {
      await this.$api.managed_account.delete({ managed_account_id });
      await this.get_managed_accounts();
    },
    async create_managed_account() {
      await this.$api.managed_account.create({
        managed_account_name: this.managed_account_name,
        managed_account_description: this.managed_account_description,
      });
      await this.get_managed_accounts();
      this.dialog_managed_account = false;
    },
    async update_managed_account() {
      await this.$api.managed_account.update(this.managed_account_update);
      await this.get_managed_accounts();
      this.dialog_managed_account_modify = false;
    },
  },
};
</script>
