import connection from "./connection";

const resource = "/api/managed_account";
export default {
  get_all() {
    return connection.get(`${resource}`);
  },
  create(payload) {
    return connection.post(`${resource}`, payload);
  },
  delete(payload) {
    return connection.delete(`${resource}/` + payload.managed_account_id);
  },
  update(payload) {
    return connection.put(`${resource}/` + payload.managed_account_id, payload);
  },
};
