import connection from "./connection";

const resource = "/api/user";
export default {
    login(payload) {
        return connection.post(`${resource}/login`, payload)
    },
    logout(payload) {
        return connection.post(`${resource}/logout`, payload)
    },
    get_all() {
        return connection.get(`${resource}`)
    },
    create(payload) {
        return connection.post(`${resource}/create`, payload)
    },
    delete(payload) {
        return connection.delete(`${resource}/` + payload.user_id)
    },
    update(user_id, payload) {
        return connection.put(`${resource}/` + user_id, payload)
    }
}