<template>
  <v-card min-height="100%" min-width="100%" color="grey lighten-2" tile>
    <v-card-title> Redaktionelle Erwähnungen </v-card-title>
    <v-card class="ma-5" tile>
      <v-card-title>Neue Prüfung</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="3">
            <v-text-field
              data-cy="isin"
              persistent-hint
              label="ISIN"
              v-model="isin"
              @blur="update_fields"
              required
              hint="Beispiel: US0378331005"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              data-cy="time_published"
              label="Datum und Uhrzeit"
              v-model="time_published"
              required
              hint="Beispiel: 19.10.2020 16:31"
              @blur="get_market_cap"
              persistent-hint
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3">
            <v-select
              data-cy="authors"
              :items="authors"
              item-text="name"
              item-value="value"
              label="Beteiligte Autoren"
              multiple
              v-model="selected_authors"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="3">
            <v-select
              data-cy="article_types"
              :items="article_types"
              item-text="name"
              item-value="value"
              label="Erwähnung"
              v-model="article_type"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              data-cy="stock_name"
              label="Name"
              v-model="stock_name"
              required
              hint="Beispiel: Apple Inc."
              persistent-hint
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              data-cy="market_cap_mio"
              label="Marktkapitalisierung"
              v-model="market_cap_mio"
              required
              hint="Beispiel: 201.3 Mio. €"
              persistent-hint
              suffix="Mio. €"
            ></v-text-field>
          </v-col>
        </v-row>
        <div align="right">
          <v-btn depressed data-cy="audit" @click="start_audit" color="primary"
            >Prüfen</v-btn
          >
        </div>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialog" max-width="800"
      ><audit-result
        @delete_audit="delete_audit"
        @save_audit="save_audit"
        @get_audits="
          dialog = false;
          get_audits();
        "
        @get_update="get_update()"
        :article_types="article_types"
        :dialog="dialog"
        :audit_results="audit_results"
      >
      </audit-result>
    </v-dialog>

    <articles @get_audits="get_audits()"></articles>

    <v-card class="ma-5" tile>
      <v-card-title>Durchgeführte Prüfungen</v-card-title>
      <v-card-text>
        <v-card tile flat color="grey lighten-3" class="mt-3 pa-3">
          <p v-if="audits.length === 0">Bisher keine Prüfungen durchgeführt.</p>
          <v-simple-table dense v-if="audits.length > 0">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">ISIN</th>
                  <th class="text-left">Aktie</th>
                  <th class="text-left">Veröffentlichungszeitpunkt</th>
                  <th class="text-left">Autoren</th>
                  <th class="text-center">Verstöße</th>
                  <th class="text-center">Prüfung abgeschlossen</th>
                  <th class="text-left"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(a, i) in audits" :key="i">
                  <td>{{ a.isin }}</td>
                  <td>{{ a.stock_name }}</td>
                  <td>{{ a.time_published }}</td>
                  <td>{{ a.authors }}</td>
                  <td class="text-center">
                    <v-icon
                      :data-cy="`is_violation_${a.isin}`"
                      v-if="a.violation"
                      color="primary"
                      >mdi-alert</v-icon
                    >
                    <span v-if="!a.violation" color="success">-</span>
                  </td>
                  <td class="text-center">
                    <v-icon
                      :data-cy="`audit_completed_${a.isin}`"
                      v-if="a.audit_completed"
                      color="success"
                      >mdi-check</v-icon
                    >
                    <v-icon v-if="!a.audit_completed" color="primary"
                      >mdi-alert</v-icon
                    >
                  </td>
                  <td>
                    <v-btn
                      :data-cy="`load_audit_${a.isin}`"
                      @click="load_audit(a.audit_id)"
                      fab
                      icon
                      small
                      ><v-icon>mdi-magnify</v-icon></v-btn
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
        <v-container>
          <v-row justify="center">
            <v-col cols="8">
              <v-container class="max-width">
                <v-pagination v-model="page" :length="pages"></v-pagination>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <v-card class="ma-5" tile>
      <v-card-title>PDF-Report generieren</v-card-title>
      <v-card-text>
        <v-row align="center">
          <v-col cols="12" md="6">
            <v-card outlined>
              <v-date-picker
                width="100%"
                v-model="month_picker"
                locale="de"
                type="month"
              ></v-date-picker>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <div align="center">
              <v-btn color="primary" depressed @click="show_pdf">
                <v-icon class="mr-2">mdi-printer</v-icon>Drucken
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
import AuditResult from "@/components/AuditResult";
import Articles from "@/components/Articles";

export default {
  components: {
    AuditResult,
    Articles,
  },
  async created() {
    var { data } = await this.$api.article_check.get_options();
    this.authors = data.authors;
    this.article_types = data.article_types;
    this.time_published = data.current_time;
    this.get_audits();
  },
  data: () => ({
    month_picker: new Date().toISOString().substr(0, 7),
    isin: "",
    time_published: "",
    market_cap_mio: "",
    stock_name: "",
    article_type: "article_long",
    selected_authors: [],
    article_types: [],
    authors: [],
    audit_results: null,
    audits: [],
    dialog: false,
    pages: 0,
    page: 1,
  }),
  methods: {
    show_pdf() {
      let month = this.month_picker.split("-")[1];
      let year = this.month_picker.split("-")[0];
      window.open(
        `api/article_check/report?year=${year}&month=${month}`,
        "_blank"
      );
    },
    update_fields() {
      this.get_stock_name();
      this.get_market_cap();
    },
    async get_audits() {
      var { data } = await this.$api.article_check.get_audits({
        page: this.page,
      });
      this.audits = data.audits;
      this.pages = data.pages;
      this.dialog = false;
    },
    async get_stock_name() {
      if (this.isin.length === 0) {
        this.$store.commit("snackbar/setSnackbarData", {
          message: "Bitte ISIN angeben",
          color: "error",
          timeout: 6000,
        });
        return;
      }
      var { data } = await this.$api.article_check.get_stock_name(this.isin);
      this.stock_name = data.stock_name;
    },
    async get_market_cap() {
      if (this.isin.length === 0) {
        this.$store.commit("snackbar/setSnackbarData", {
          message: "Bitte ISIN angeben",
          color: "error",
          timeout: 6000,
        });
        return;
      }
      var { data } = await this.$api.article_check.get_market_cap({
        isin: this.isin,
        time_published: this.time_published.split(" ")[0],
      });
      this.market_cap_mio = data.market_cap_mio;
    },
    async save_audit() {
      await this.$api.article_check.save_audit(this.audit_results);
      this.get_audits();
    },
    async start_audit() {
      var { data } = await this.$api.article_check.perform_audit({
        isin: this.isin,
        time_published: this.time_published,
        market_cap_mio: this.market_cap_mio,
        selected_authors: this.selected_authors,
        article_type: this.article_type,
        stock_name: this.stock_name,
      });
      this.audit_results = data;
      this.dialog = true;
    },
    async get_update() {
      var { data } = await this.$api.article_check.perform_audit({
        isin: this.audit_results.meta.isin,
        time_published: this.audit_results.meta.time_published,
        market_cap_mio: this.audit_results.meta.market_cap_mio,
        selected_authors: this.audit_results.meta.authors.split(", "),
        article_type: this.audit_results.meta.article_type,
        stock_name: this.audit_results.meta.stock_name,
        audit_id: this.audit_results.meta.audit_id,
      });
      this.audit_results = data;
      await this.save_audit();
    },
    async load_audit(audit_id) {
      var { data } = await this.$api.article_check.get_audit(audit_id);
      this.audit_results = data;
      this.dialog = true;
    },
    async delete_audit() {
      await this.$api.article_check.delete_audit(
        this.audit_results.meta.audit_id
      );
      this.dialog = false;
      this.get_audits();
    },
  },
  watch: {
    async page() {
      this.get_audits();
    },
  },
};
</script>
