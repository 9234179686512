import connection from "./connection";

const resource = "/api/blacklist";
export default {
    get_all() {
        return connection.get(`${resource}`)
    },
    get_all_public() {
        return connection.get(`${resource}/public`)
    },
    get_all_violations() {
        return connection.get(`${resource}/violations`)
    },
    create(payload) {
        return connection.post(`${resource}`, payload)
    },
    delete_one(payload) {
        return connection.delete(`${resource}/${payload.id}`)
    },
    delete_multiple(payload) {
        return connection.delete(`${resource}?fixing_date=${payload.fixing_date}&effective_date=${payload.effective_date}`)
    },
    send_email(payload) {
        return connection.post(`${resource}/send_email`, payload)
    },
}