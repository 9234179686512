<template>
  <v-card v-if="audit_results !== null">
    <v-card-title>Prüfergebnis</v-card-title>
    <v-card-text>
      <v-card tile flat color="grey lighten-3" class="mt-3 pa-3">
        ISIN: {{ audit_results.meta.isin }}<br />
        Aktie: {{ audit_results.meta.stock_name }}<br />
        Marktkapitalisierung (Mio. €): {{ audit_results.meta.market_cap_mio
        }}<br />
        Veröffentlichungszeitpunkt des Artikels:
        {{ audit_results.meta.time_published }} <br />
        Autoren: {{ audit_results.meta.authors }}<br />
        Artikel-Typ:
        {{
          article_types.filter(
            (x) => x.value == audit_results.meta.article_type
          )[0].name
        }}<br />
        Sperrfirst (Tage): {{ audit_results.meta.blocking_period_days }}<br />
        Prüfung abgeschlossen:
        {{ audit_results.meta.audit_completed ? "Ja" : "Nein" }}<br />
      </v-card>

      <v-card tile flat color="grey lighten-3" class="mt-3 pa-3">
        <p>
          Redakteure, die zum Zeitpunkt der Erwähnung investiert waren:
        </p>
        <p v-if="audit_results.portfolio_positions.length === 0">-</p>
        <v-simple-table
          dense
          v-if="audit_results.portfolio_positions.length > 0"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Redakteur</th>
                <th class="text-left">Instrument</th>
                <th class="text-left">Long / Short</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(a, i) in audit_results.portfolio_positions" :key="i">
                <td>{{ a.username }}</td>
                <td>{{ a.instrument }}</td>
                <td>{{ a.direction }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>

      <v-card tile flat color="grey lighten-3" class="mt-3 pa-3">
        <p>
          Transaktionen innerhalb der Sperrfirst:
        </p>
        <p v-if="audit_results.transactions.length === 0">Keine</p>
        <v-simple-table dense v-if="audit_results.transactions.length > 0">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Redakteur</th>
                <th class="text-left">Transaktionstyp</th>
                <th class="text-left">Instrument</th>
                <th class="text-left">Zeitpunkt</th>
                <th class="text-center">Autor</th>
                <th class="text-center">Verstoß</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(a, i) in audit_results.transactions" :key="i">
                <td>{{ a.username }}</td>
                <td>{{ a.transaction_type }}</td>
                <td>{{ a.instrument }}</td>
                <td>{{ a.execution_time }}</td>
                <td class="text-center">
                  <v-icon
                    data-cy="is_author_true"
                    color="success"
                    v-if="a.is_author"
                    >mdi-check</v-icon
                  >
                </td>
                <td class="text-center">
                  <v-icon
                    data-cy="is_violation"
                    color="primary"
                    v-if="a.violation"
                    >mdi-check</v-icon
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>

      <div align="right" class="mt-4">
        <v-btn
          class="mx-2"
          depressed
          v-if="audit_results.meta.audit_id !== null"
          @click="update()"
          data-cy="update_audit"
          color="success"
          >Update</v-btn
        >
        <v-btn
          class="mx-2"
          data-cy="save_audit"
          v-else
          depressed
          @click="save()"
          color="success"
          >Speichern</v-btn
        >
        <v-btn
          depressed
          class="mx-2"
          v-if="audit_results.meta.audit_id !== null"
          data-cy="delete_audit"
          @click="delete_audit()"
          color="primary"
          >Löschen</v-btn
        >
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ["audit_results", "article_types"],
  async created() {},
  data: () => ({}),
  methods: {
    get_audits() {
      this.$emit("get_audits");
    },
    async update() {
      this.$emit("get_update");
    },
    async save() {
      this.$emit("save_audit");
    },
    async delete_audit() {
      this.$emit("delete_audit");
    },
  },
};
</script>
