import connection from "./connection";

const resource = "/api/session";
export default {
    get_all() {
        return connection.get(`${resource}`)
    },
    delete(payload) {
        return connection.delete(`${resource}/` + payload.int_id)
    },
}