<template>
  <v-app-bar app color="grey lighten-5">
    <v-container style="max-width: 1000px" class="d-flex align-center">
      <v-img
        data-cy="logo"
        src="@/assets/traderfox.png"
        max-width="140"
        @click="click_logo"
      ></v-img>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        outlined
        tile
        v-if="$store.getters['user/logged_in']"
        data-cy="logout"
        @click="logout()"
        ><v-icon>mdi-logout</v-icon
        ><span class="d-none d-sm-block">Logout</span></v-btn
      >
    </v-container>
  </v-app-bar>
</template>

<script>
export default {
  components: {},
  methods: {
    async logout() {
      await this.$store.dispatch("user/logout");
      this.$router.push({ name: "Login" });
    },
    click_logo() {
      if (this.$store.getters["user/logged_in"]) {
        this.$router.push({ name: "Dashboard" });
      } else {
        this.$router.push({ name: "ISINLookup" });
      }
    },
  },
};
</script>
