import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store/store';
import vuetify from './plugins/vuetify';
import { api } from "./connections/api"

Vue.config.productionTip = false

Vue.prototype.$api = api


const app = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
window.app = app
