import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user'
import snackbar from './modules/snackbar'

Vue.use(Vuex)

export const store = new Vuex.Store({
  strict: true,
  modules: {
    user,
    snackbar,
  }
})