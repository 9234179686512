import user from "./user";
import transaction from "./transaction";
import session from "./session";
import blacklist from "./blacklist";
import blacklist_portfolio from "./blacklist_portfolio";
import trading_account from "./trading_account";
import managed_account from "./managed_account";
import article_check from "./article_check";
import article from "./article";
import rules from "./rules";

export const api = {
  user,
  transaction,
  session,
  blacklist,
  blacklist_portfolio,
  trading_account,
  managed_account,
  article_check,
  article,
  rules,
};
