<template>
  <v-card min-height="100%" min-width="100%" color="grey lighten-2" tile>
    <v-card-title> Blacklist-Verstöße</v-card-title>
    <v-card class="ma-5" tile>
      <v-card-title> Verstöße gegen Index-Blacklist</v-card-title>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Mitarbeiter</th>
              <th class="text-left">ISIN</th>
              <th class="text-left">Aktie</th>
              <th class="text-left">Transaktions-Typ</th>
              <th class="text-left">Zeit</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="b in violations_index" :key="b.id">
              <td>{{ b.user_name }}</td>
              <td>{{ b.isin }}</td>
              <td>{{ b.stock_name }}</td>
              <td>{{ b.transaction_type }}</td>
              <td>{{ b.execution_time_str }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>

    <v-card class="ma-5" tile>
      <v-card-title> Verstöße gegen Portfolio-Blacklist</v-card-title>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Mitarbeiter</th>
              <th class="text-left">ISIN</th>
              <th class="text-left">Aktie</th>
              <th class="text-left">Transaktions-Typ</th>
              <th class="text-left">Zeit</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="b in violations_portfolio" :key="b.id">
              <td>{{ b.user_name }}</td>
              <td>{{ b.isin }}</td>
              <td>{{ b.stock_name }}</td>
              <td>{{ b.transaction_type }}</td>
              <td>{{ b.execution_time_str }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </v-card>
</template>

<script>
export default {
  created() {
    this.get_violations();
  },
  data: () => ({
    violations_index: [],
    violations_portfolio: [],
  }),
  methods: {
    async get_violations() {
      let { data } = await this.$api.blacklist.get_all_violations();
      this.violations_index = data.violations_index;
      this.violations_portfolio = data.violations_portfolio;
    },
  },
};
</script>

<style></style>
