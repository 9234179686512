<template>
  <v-app style="background-color: rgb(170, 170, 170) !important">
    <Appbar></Appbar>
    <Snackbar></Snackbar>
    <Confirm ref="confirm"></Confirm>

    <v-main>
      <v-container
        style="max-width: 1000px"
        fill-height
        v-if="$vuetify.breakpoint.mdAndUp"
      >
        <router-view></router-view>
      </v-container>
      <div v-else style="height: 100%" class="ma-0 pa-0">
        <router-view></router-view>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import Snackbar from "@/components/Snackbar";
import Appbar from "@/components/Appbar";
import Confirm from "@/components/Confirm";

export default {
  name: "App",

  components: {
    Snackbar,
    Appbar,
    Confirm,
  },
  mounted() {
    this.$root.$confirm = this.$refs.confirm;
    document.title = "Compliance-Tool";
  },

  data: () => ({
    //
  }),
};
</script>
