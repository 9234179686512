
<template>
  <v-row justify="center">
    <v-col cols="12" sm="6">
      <div align="center" class="my-3"></div>
      <v-card class="ma-5" tile>
        <v-card-title>Login</v-card-title>
        <v-card-text>
          <v-form>
            <v-text-field
              id="email"
              v-model="email"
              label="Email"
              data-cy="email"
            ></v-text-field>
            <v-text-field
              id="password"
              v-model="password"
              :append-icon="visible ? 'mdi-eye' : 'mdi-eye-off'"
              :type="visible ? 'text' : 'password'"
              label="Passwort"
              @click:append="visible = !visible"
              @keydown.enter="submit"
              data-cy="password"
            ></v-text-field>
          </v-form>

          <v-btn
            depressed
            tile
            style="width: 100%"
            id="login"
            class="success mt-3"
            data-cy="login"
            @keyup.enter="submit()"
            @click="submit()"
            >{{ "login" }}</v-btn
          >
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      password: "",
      visible: false,
    };
  },
  methods: {
    async submit() {
      let payload = {
        email: this.email,
        password: this.password,
      };
      await this.$store.dispatch("user/login", payload);
      this.$router.push({ name: "Dashboard" });
    },
  },
};
</script>
