<template>
  <v-card min-height="100%" min-width="100%" color="grey lighten-2" tile>
    <v-card-title> Index-Umschichtungen </v-card-title>
    <v-card class="ma-5" tile>
      <div v-cloak @drop.prevent="addFile" @dragover.prevent>
        <v-card-title>Files hier ablegen</v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-menu
                v-model="menu_fixing_date"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fixing_date"
                    label="Fixing Date"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fixing_date"
                  locale="de"
                  @input="menu_fixing_date = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" md="6">
              <v-menu
                v-model="menu_effective_date"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="effective_date"
                    label="Effective Date"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="effective_date"
                  locale="de"
                  @input="menu_effective_date = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <div v-for="(file, f) in files" :key="f">
            <span>{{ file.name }} ({{ file.size }} kb)</span>

            <v-btn icon @click="removeFile(file)"
              ><v-icon>mdi-delete</v-icon></v-btn
            >
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              depressed
              :disabled="uploadDisabled"
              @click="upload"
              color="success"
              ><v-icon class="mr-2">mdi-file-upload</v-icon>Upload</v-btn
            >
          </v-card-actions>
        </v-card-text>
      </div>
    </v-card>
    <v-card class="ma-5" tile
      ><v-card-title>
        <span class="headline">Transaktionen</span>
        <v-spacer></v-spacer
        ><v-btn
          class="ma-1 success"
          text
          @click="$api.blacklist.send_email({})"
        >
          <v-icon class="mr-2">mdi-email-send</v-icon>Mitarbeiter
          informieren</v-btn
        ><v-btn class="ma-1 primary" text @click="delete_all()"
          ><v-icon class="mr-2">mdi-delete</v-icon> Einträge löschen
        </v-btn></v-card-title
      >
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">ISIN</th>
              <th class="text-left">Aktie</th>
              <th class="text-left">Index</th>
              <th class="text-left">Status</th>
              <th class="text-left">Fixing Date</th>
              <th class="text-center">Rebalancing Date</th>
              <th class="text-center">Löschen</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="b in blacklist" :key="b.id">
              <td>{{ b.isin }}</td>
              <td>{{ b.stock_name }}</td>
              <td>{{ b.index_name }}</td>
              <td>{{ b.status }}</td>
              <td>{{ b.fixing_date }}</td>
              <td>{{ b.effective_date }}</td>
              <td class="text-center">
                <v-btn
                  fab
                  x-small
                  text
                  color="primary"
                  @click="delete_one(b.id)"
                  ><v-icon>mdi-delete</v-icon></v-btn
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table></v-card
    >
  </v-card>
</template>

<script>
export default {
  created() {
    this.get_blacklist();
  },
  data: () => ({
    blacklist: [],
    files: [],
    menu_fixing_date: false,
    menu_effective_date: false,
    fixing_date: new Date().toISOString().substr(0, 10),
    effective_date: new Date().toISOString().substr(0, 10),
  }),
  computed: {
    uploadDisabled() {
      return this.files.length === 0;
    },
  },
  methods: {
    async get_blacklist() {
      let { data } = await this.$api.blacklist.get_all();
      this.blacklist = data.blacklist;
    },
    addFile(e) {
      let droppedFiles = e.dataTransfer.files;
      if (!droppedFiles) return;
      [...droppedFiles].forEach((f) => {
        this.files.push(f);
      });
    },
    removeFile(file) {
      this.files = this.files.filter((f) => {
        return f != file;
      });
    },
    async upload() {
      let formData = new FormData();
      this.files.forEach((f, i) => {
        formData.append(
          this.fixing_date + "|" + this.effective_date + "|" + i,
          f
        );
      });
      await this.$api.blacklist.create(formData);
      await this.get_blacklist();
      this.files = [];
    },
    async delete_one(id) {
      this.$root.$confirm
        .open(
          "Bestätigen",
          `Bist du dir sicher, dass du diesen Eintrag löschen möchtest?`,
          {
            color: "error",
          }
        )
        .then((confirm) => {
          if (confirm) {
            this.$api.blacklist
              .delete_one({ id: id })
              .then(() => this.get_blacklist());
          }
        });
    },
    delete_all() {
      this.$root.$confirm
        .open(
          "Bestätigen",
          `Bist du dir sicher, dass alle Einträge mit dem Fixing-Date ${this.fixing_date} und dem Effective-Date ${this.effective_date} gelöscht werden sollen?`,
          {
            color: "error",
          }
        )
        .then((confirm) => {
          if (confirm) {
            this.$api.blacklist
              .delete_multiple({
                fixing_date: this.fixing_date,
                effective_date: this.effective_date,
              })
              .then(() => this.get_blacklist());
          }
        });
    },
  },
};
</script>

<style>
[v-cloak] {
  display: none;
}
</style>