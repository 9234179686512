<template>
  <v-card class="ma-5" tile>
    <v-card-title>Ungeprüfte Artikel</v-card-title>
    <v-card-text>
      <v-card tile flat color="grey lighten-3" class="mt-3 pa-3">
        <p v-if="articles.length === 0">Bisher keine Prüfungen durchgeführt.</p>
        <v-simple-table dense v-if="articles.length > 0">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">ISIN</th>
                <th class="text-left">Veröffentlichungszeitpunkt</th>
                <th class="text-left">Autoren</th>
                <th class="text-left">Artikel-Typ</th>
                <th class="text-left">Aktie</th>
                <th class="text-left">Marktkapitalisierung Mio. €</th>
                <th class="text-center"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(a, i) in articles" :key="i">
                <td>{{ a.isin }}</td>
                <td>{{ a.time_published }}</td>
                <td>{{ a.authors }}</td>
                <td>{{ a.article_type }}</td>
                <td>
                  <v-text-field
                    v-model="a.stock_name"
                    dense
                    hide-detail
                  ></v-text-field>
                </td>
                <td>
                  <v-text-field
                    v-model="a.market_cap_mio"
                    dense
                    hide-detail
                  ></v-text-field>
                </td>
                <td class="text-center">
                  <v-btn
                    small
                    class="primary"
                    depressed
                    :data-cy="`audit_${a.isin}`"
                    @click="audit(a.article_id, a.stock_name, a.market_cap_mio)"
                    >Prüfen</v-btn
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
      <v-container>
        <v-row justify="center">
          <v-col cols="8">
            <v-container class="max-width">
              <v-pagination v-model="page" :length="pages"></v-pagination>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  async created() {
    this.get_articles();
  },
  data: () => ({
    articles: [],
    pages: 0,
    page: 1,
  }),
  methods: {
    async get_articles() {
      var { data } = await this.$api.article.get_all({ page: this.page });
      this.articles = data.articles;
      this.pages = data.pages;
    },
    async audit(article_id, stock_name, market_cap_mio) {
      await this.$api.article.audit({ article_id, stock_name, market_cap_mio });
      this.get_articles();
      this.$emit("get_audits");
    },
  },
  watch: {
    async page() {
      this.get_articles();
    },
  },
};
</script>
