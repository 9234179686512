<template>
  <v-card min-height="100%" min-width="100%" color="grey lighten-2" tile>
    <v-card-title> Dein Portfolio und Transaktionen </v-card-title>
    <v-card class="ma-5" tile>
      <v-card-title>
        <span class="headline">Neue Transaktion</span
        ><v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="pl-2" color="grey" v-bind="attrs" v-on="on">
              mdi-information-outline
            </v-icon>
          </template>
          <v-container style="max-width: 500px">
            <p>
              Hinweis: Das Feld "ISIN" bezieht sich immer auf den Basiswert.
              Long und Short im Feld "Transaktions-Typ" bezieht sich darauf, ob
              ihr von fallenden oder steigenden Kursen des Basiswerts
              profitiert. Beispiel: Wird eine Put-Option auf die Apple-Aktie
              gekauft, muss die ISIN von Apple mit Transaktions-Typ "Short:
              Positions-Eröffnung" und Instrument "Derivatives Produkt"
              ausgewählt sein.
            </p>
            <p>
              Liegt das Kaufdatum einer Ersterfassung länger als ein Jahr in der
              Vergangenheit und kann das Kaufdatum nicht mehr ermittelt werden,
              muss stattdessen das Kaufdatum auf den 01.01.2011 gesetzt werden.
            </p>
          </v-container>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              data-cy="isin"
              persistent-hint
              label="ISIN (Basiswert)"
              v-model="isin"
              required
              hint="Beispiel: US0378331005"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              data-cy="execution_time"
              label="Datum und Uhrzeit"
              v-model="execution_time"
              required
              hint="Beispiel: 19.10.2020 16:31"
              persistent-hint
            ></v-text-field>
          </v-col>
          <v-col class="d-flex" cols="12" sm="6">
            <v-select
              data-cy="transaction_types"
              :items="transaction_types"
              item-text="name"
              item-value="transaction_type_id"
              label="Transaktions-Typ"
              v-model="selected_transaction_type"
            ></v-select>
          </v-col>
          <v-col class="d-flex" cols="12" sm="6">
            <v-select
              data-cy="instrument"
              :items="instruments"
              item-text="name"
              item-value="instrument_id"
              label="Instrument"
              v-model="selected_instrument"
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :loading="loading"
          color="success"
          text
          @click="create_transaction()"
          data-cy="create_transaction"
        >
          hinzufügen
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card class="ma-5" tile>
      <v-card-title>
        <span class="headline">Mein Portfolio</span>
      </v-card-title>
      <v-card-text v-if="portfolio.length === 0"
        >Das Portfolio ist leer.</v-card-text
      >
      <v-card-text v-if="portfolio.length > 0">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">ISIN</th>
                <th class="text-left">Name</th>
                <th class="text-left">Instrument</th>
                <th class="text-left">Long / Short</th>
                <th class="text-center">Schließen</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(position, i) in portfolio" :key="i">
                <td>{{ position.isin }}</td>
                <td>{{ position.name }}</td>
                <td>{{ position.instrument }}</td>
                <td>{{ position.direction }}</td>
                <td class="text-center">
                  <v-btn
                    fab
                    x-small
                    text
                    color="primary"
                    :data-cy="'close_pf_position_' + i"
                    @click="
                      selected_delete_pf_position = position;
                      delete_dialog = true;
                    "
                    ><v-icon>mdi-close</v-icon></v-btn
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>

    <v-card class="ma-5" tile>
      <v-card-title>
        <span class="headline">Meine Transaktionen</span>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          fab
          text
          @click="$router.push({ name: 'TransactionPanelPrint' })"
          ><v-icon>mdi-printer</v-icon></v-btn
        >
      </v-card-title>
      <v-card-text v-if="transactions.length === 0"
        >Noch keine Transaktionen hinzugefügt.</v-card-text
      >
      <v-card-text v-if="transactions.length > 0">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">ISIN</th>
                <th class="text-left">Name</th>
                <th class="text-left">Datum / Uhrzeit</th>
                <th class="text-left">Transaktions-Typ</th>
                <th class="text-left">Instrument</th>
                <th class="text-center">Löschen</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="t in transactions" :key="t.name">
                <td>{{ t.isin }}</td>
                <td>{{ t.name }}</td>
                <td>{{ t.execution_time }}</td>
                <td>{{ t.transaction_type }}</td>
                <td>{{ t.instrument }}</td>
                <td class="text-center">
                  <v-btn
                    fab
                    x-small
                    text
                    color="primary"
                    @click="delete_transaction(t.transaction_id)"
                    ><v-icon>mdi-delete</v-icon></v-btn
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="delete_dialog" max-width="550">
      <v-card v-if="selected_delete_pf_position !== null"
        ><v-card-title class="headline primary white--text">
          Willst du die Position
          {{ selected_delete_pf_position.name }} schließen?
        </v-card-title>
        <v-card-text class="pa-5">
          <v-text-field
            class="pa-6"
            data-cy="execution_time"
            label="Datum und Uhrzeit der Transaktion"
            v-model="execution_time"
            required
            hint="Beispiel: 19.10.2020 16:31"
            persistent-hint
          ></v-text-field>
          <div align="right">
            <v-btn
              data-cy="close_position"
              depressed
              class="primary mt-6"
              @click="close_position"
              >Position schließen</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
export default {
  created() {
    this.fetch_options();
    this.get_all_transactions();
  },
  data: () => ({
    loading: false,
    isin: "",
    delete_dialog: false,
    selected_delete_pf_position: null,
    execution_time: "",
    selected_transaction_type: null,
    selected_instrument: null,
    transaction_types: [],
    instruments: [],
    transactions: [],
    portfolio: [],
  }),
  methods: {
    async fetch_options() {
      let { data } = await this.$api.transaction.get_options();
      this.transaction_types = data.transaction_types;
      this.selected_transaction_type =
        data.transaction_types[0].transaction_type_id;
      this.selected_instrument = data.instruments[0].instrument_id;
      this.instruments = data.instruments;
      this.execution_time = data.execution_time;
    },
    async create_transaction() {
      let payload = {
        isin: this.isin,
        execution_time: this.execution_time,
        transaction_type_id: this.selected_transaction_type,
        instrument_id: this.selected_instrument,
      };
      this.loading = true;
      try {
        await this.$api.transaction.create(payload);
        this.isin = "";
        await this.get_all_transactions();
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
    async get_all_transactions() {
      let { data } = await this.$api.transaction.get_all();
      this.transactions = data.transactions;
      this.portfolio = data.portfolio;
    },
    async close_position() {
      this.selected_delete_pf_position["execution_time"] = this.execution_time;
      await this.$api.transaction.close_position(
        this.selected_delete_pf_position
      );
      await this.get_all_transactions();
      this.delete_dialog = false;
    },
    async delete_transaction(transaction_id) {
      this.$root.$confirm
        .open(
          "Bestätigen",
          "Bist du dir sicher, dass diese Transaktion gelöscht werden soll? Diese Aktion kann nicht rückgängig gemacht werden.",
          {
            color: "error",
          }
        )
        .then((confirm) => {
          if (confirm) {
            this.$api.transaction
              .delete({ transaction_id })
              .then(() => this.get_all_transactions());
          }
        });
    },
  },
};
</script>
