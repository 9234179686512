<template>
  <v-snackbar v-model="show" :color="color" :timeout="timeout">
    {{ message }}
    <template v-slot:action="{ attrs }">
      <v-btn
        data-cy="snackbar_close"
        color="black"
        v-bind="attrs"
        text
        @click="show = false"
        >schließen</v-btn
      >
    </template>
  </v-snackbar>
</template>

<script>
export default {
  created: function () {
    this.$store.watch(
      (state) => state.snackbar.snackbarData,
      () => {
        const message = this.$store.state.snackbar.snackbarData.message;
        if (message !== "") {
          this.show = true;
          this.message = this.$store.state.snackbar.snackbarData.message;
          this.color = this.$store.state.snackbar.snackbarData.color;
          this.timeout = this.$store.state.snackbar.snackbarData.timeout;
          this.$store.commit("snackbar/setSnackbarData", {
            message: "",
            timeout: 6000,
            color: "error",
          });
        }
      }
    );
  },
  data: () => ({
    message: "",
    timeout: 6000,
    show: false,
    color: null,
  }),
  methods: {},
};
</script>