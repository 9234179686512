<template>
  <v-card min-height="100%" min-width="100%" color="grey lighten-2" tile>
    <v-card class="ma-5" tile>
      <v-card-title>
        Aktuelle Positionen der Traderfox-Mitarbeiter anzeigen
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              data-cy="isin"
              label="Nach ISIN suchen"
              outlined
              hide-details
              v-model="isin"
              required
              append-outer-icon="mdi-magnify"
              @click:append-outer="get_isin_employee_holdings"
              @keyup.enter.native="get_isin_employee_holdings"
              hint="Beispiel: US0378331005"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="ma-5" tile>
      <v-card-title>
        Verhaltensrichtlinien bei TraderFox
      </v-card-title>
      <v-card-text>
        <p>
          TraderFox beschäftigt Redakteure, die selbst börsenbegeistert sind und aktiv handeln, damit wir unseren unseren Lesern eine spannende und informative Berichterstattung über relevante Themen anbieten können. Gleichzeitig unterliegen bei uns angestellte Redakteure strengen Verhaltensvorschriften, die sicherstellen, dass bei unseren journalistischen Veröffentlichungen die Interessen unsere Leser an erster Stelle stehen.
        </p>
        <p>


<ul>
  <li>TraderFox klärt über alle Interessenskonflikte und Eigenpositionen in der Berichterstattung über Aktien auf.</li>
  <li>Alle bei TraderFox angestellten Redakteure müssen ihre Depotkonten und Eigentransaktionen offenlegen.</li>
</ul>
</p>
<p>
Unter jedem journalistischen Artikel der TraderFox GmbH sind die beteiligten Redakteure und die Eigenpositionen aufgeführt, die zum Zeitpunkt der Veröffentlichung gehalten werden. In diesem Compliance-Tool können die Eigenpositionen unserer Redakteure abgefragt werden. Niemals dürfen Redakteure von Kursveränderungen profitieren, die zum Beispiel von einer Depotaufnahme ausgelöst wurden.
</p>
<p>
Hinweis: Nicht gemeldet werden müssen von unseren Redakteuren folgende Eigenpositionen:

<ul>
  <li>ETF-Positionen</li>
  <li>Transaktionen auf große Marktindizes wie DAX, Dow Jones, ...</li>
  <li>Transaktionen auf Rohstoffe</li>
  <li>Transaktionen auf Forex und Krypowährungen</li>
  <li>Aktien, die passiv über Vermögensverwaltungsmandate wie z.B. QualitInvest gehalten werden und auf deren Auswahl Redakteure keinen Einfluss haben.</li>
  <li>Marktneutrale und kombinierte Strategien, wo eine Aktie nur ein Teilelement der gesamten Strategie ist und wo die Spekulation auf eine bestimmte Richtung des Basiswerts nicht das Kernanliegen ist. Beispiel: Pair-Trading, Stillhaltergeschäfte mit Optionen ...</li>
</ul>
        </p>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    isin: "",
  }),
  methods: {
    async get_isin_employee_holdings() {
      this.$router.push({
        name: "ISINSLookup",
        params: { isins: "isin=" + this.isin },
      });
    },
  },
};
</script>
