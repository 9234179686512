<template>
  <v-card min-height="100%" min-width="100%" color="grey lighten-2" tile>
    <v-card-title>Nutzer</v-card-title>
    <v-row no-gutters>
      <v-col
        cols="12"
        sm="6"
        v-for="(employee, e) in employees"
        :key="e"
        class="my-2"
      >
        <v-card class="mx-5" tile height="100%">
          <v-card-title
            :class="employee.inactive ? 'grey--text' : ''"
            style="
              width: 100%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            "
            >{{ employee.name }}
            {{ employee.inactive ? "(inaktiv)" : "" }}
          </v-card-title>
          <v-card-text>
            <v-chip
              :disabled="employee.inactive"
              v-for="(role, r) in ['Admin', 'Index', 'Mitarbeiter']"
              :key="r"
              :outlined="employee.roles.includes(role) ? false : true"
              class="mr-1"
              color="primary"
              @click="change_roles(employee.user_id, employee.roles, role)"
            >
              {{ role }}
              {{ employee.role }}
            </v-chip>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="change_status(employee.user_id, !employee.inactive)"
                  ><v-icon
                    >{{
                      employee.inactive
                        ? "mdi-account-plus"
                        : "mdi-account-minus"
                    }}
                  </v-icon></v-btn
                >
              </template>
              <span>{{
                employee.inactive
                  ? "Account wieder aktivieren."
                  : "Account deaktivieren."
              }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="
                    $router.push({
                      name: 'AdminEmployeeView',
                      params: { user_id: employee.user_id },
                    })
                  "
                  ><v-icon>mdi-text-box-search-outline </v-icon></v-btn
                >
              </template>
              <span>Portfolio und Transaktionshistorie ansehen</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="
                    user_id = employee.user_id;
                    dialog_change = true;
                    changed_value = '';
                  "
                  ><v-icon>mdi-rename-box</v-icon></v-btn
                >
              </template>
              <span>Nutzerdaten ändern</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="delete_employee(employee.user_id)"
                  ><v-icon>mdi-delete</v-icon></v-btn
                >
              </template>
              <span>Mitarbeiter löschen</span>
            </v-tooltip>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn class="success ma-5" fab @click="open_dialog()"
        ><v-icon>mdi-plus</v-icon></v-btn
      >
    </v-card-actions>
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Neuen Nutzer anlegen</span>
        </v-card-title>
        <v-card-text>
          <v-text-field
            label="Email-Adresse"
            v-model="email"
            required
          ></v-text-field>
          <v-text-field label="Name" v-model="username" required></v-text-field>
          <v-text-field
            label="Passwort"
            type="password"
            v-model="password"
            required
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="create_employee()">
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_change" max-width="600px">
      <v-card class="pt-5">
        <v-card-text>
          <v-select
            v-model="to_change"
            :items="to_change_options"
            item-text="text"
            item-value="value"
          ></v-select>
          <v-text-field
            :label="
              to_change_options.filter((a) => a.value === to_change)[0].text +
              ' zu'
            "
            v-model="changed_value"
            :type="to_change === 'password' ? 'password' : ''"
            required
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            text
            @keyup.enter="change_employee()"
            @click="change_employee()"
          >
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
export default {
  created() {
    this.fetch_employees();
  },
  data: () => ({
    employees: [],
    dialog: false,
    dialog_change: false,
    username: "",
    password: "",
    email: "",
    user_id: null,
    to_change_options: [
      {
        text: "Email-Adresse ändern",
        value: "email",
        hint: "Neue Email-Adresse",
      },
      { text: "Namen ändern", value: "username" },
      { text: "Passwort ändern", value: "password" },
    ],
    to_change: "email",
    changed_value: "",
  }),
  methods: {
    open_dialog() {
      this.username = "";
      this.password = "";
      this.dialog = true;
    },
    async fetch_employees() {
      let { data } = await this.$api.user.get_all();
      this.employees = data.employees;
    },
    async create_employee() {
      if (
        this.username.length === 0 ||
        this.password.length === 0 ||
        this.email.length === 0
      ) {
        return null;
      }
      let payload = {
        username: this.username,
        password: this.password,
        email: this.email,
      };
      await this.$api.user.create(payload);

      this.dialog = false;
      this.fetch_employees();
    },
    async change_employee() {
      var payload = {};
      payload[this.to_change] = this.changed_value;
      await this.$api.user.update(this.user_id, payload);
      await this.fetch_employees();
      this.dialog_change = false;
      await this.$store.commit("snackbar/setSnackbarData", {
        message: "Erfolgreich geändert.",
        color: "success",
        timeout: 6000,
      });
    },
    async change_roles(user_id, current_roles, role) {
      let current_roles_ = JSON.parse(JSON.stringify(current_roles));
      if (current_roles_.includes(role)) {
        var index = current_roles_.indexOf(role);
        current_roles_.splice(index, 1);
      } else {
        current_roles_.push(role);
      }
      let payload = {
        roles: current_roles_,
      };
      await this.$api.user.update(user_id, payload);
      await this.fetch_employees();
    },
    async change_status(user_id, inactive) {
      let payload = {
        inactive: inactive,
      };
      await this.$api.user.update(user_id, payload);
      await this.fetch_employees();
    },
    async delete_employee(user_id) {
      this.$root.$confirm
        .open(
          "Bestätigen",
          "Bist du dir sicher, dass du diesen Mitarbeiter löschen möchtest? Diese Aktion kann nicht rückgängig gemacht werden.",
          {
            color: "error",
          }
        )
        .then((confirm) => {
          if (confirm) {
            let payload = {
              user_id: user_id,
            };
            this.$api.user.delete(payload).then(() => this.fetch_employees());
          }
        });
    },
  },
};
</script>
