import axios from "axios";
import { store } from "../store/store";

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error.response ? error.response.status : null;
    if (status === 422) {
      store.dispatch("user/logout").then(() => {
        store
          .dispatch("snackbar/setSnackbarData", {
            message: "Bitte einloggen",
            color: "error",
            timeout: 6000,
          })
          .then(() => {
            location.href = "/#/";
          });
      });
    } else if (status >= 500) {
      store.commit("snackbar/setSnackbarData", {
        message: "Ein Fehler ist aufgetreten",
        color: "error",
        timeout: 6000,
      });
    } else if (status == 403) {
      store.commit("snackbar/setSnackbarData", {
        message: "Keine Authorisation",
        color: "error",
        timeout: 6000,
      });
    } else if (status == 400) {
      store.commit("snackbar/setSnackbarData", {
        message: error.response.data.error_msg,
        color: "error",
        timeout: 6000,
      });
    } else {
      store.commit("snackbar/setSnackbarData", {
        message: "Ein Fehler ist aufgetreten",
        color: "error",
        timeout: 6000,
      });
    }
    return Promise.reject(error);
  }
);

export default axios;
