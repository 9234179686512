<template>
  <v-card min-height="100%" min-width="100%" color="grey lighten-2" tile>
    <v-card-title>
      {{ username }}s Portfolio und Transaktionshistorie
    </v-card-title>
    <v-card class="ma-5" tile>
      <v-card-title>
        <v-row>
          <v-col cols="12" md="6">
            <span class="headline">{{ username }}s Portfolio</span>
          </v-col>
          <v-col cols="12" md="6">
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="german_date"
                  label="Datum"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                @input="menu = false"
                locale="DE-de"
                v-model="date"
                no-title
                scrollable
              >
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text v-if="portfolio.length === 0"
        >{{ username }}s Portfolio ist leer.</v-card-text
      >
      <v-card-text v-if="portfolio.length > 0">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">ISIN</th>
                <th class="text-left">Name</th>
                <th class="text-left">Instrument</th>
                <th class="text-left">Long / Short</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(position, i) in portfolio" :key="i">
                <td>{{ position.isin }}</td>
                <td>{{ position.name }}</td>
                <td>{{ position.instrument }}</td>
                <td>{{ position.direction }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>

    <v-card class="ma-5" tile>
      <v-card-title>
        <span class="headline">{{ username }}s Transaktionen</span>
      </v-card-title>
      <v-card-text v-if="transactions.length === 0"
        >{{ username }} hat noch keine Transaktionen hinzugefügt.</v-card-text
      >
      <v-card-text v-if="transactions.length > 0">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">ISIN</th>
                <th class="text-left">Name</th>
                <th class="text-left">Datum / Uhrzeit</th>
                <th class="text-left">Transaktions-Typ</th>
                <th class="text-left">Instrument</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="t in transactions" :key="t.name">
                <td>{{ t.isin }}</td>
                <td>{{ t.name }}</td>
                <td>{{ t.execution_time }}</td>
                <td>{{ t.transaction_type }}</td>
                <td>{{ t.instrument }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
export default {
  created() {
    this.get_all_transactions();
  },
  computed: {
    german_date() {
      var date_dt = new Date(
        Date.UTC(
          parseInt(this.date.split("-")[0]),
          parseInt(this.date.split("-")[1]) - 1,
          parseInt(this.date.split("-")[2]),
          0,
          0,
          0
        )
      );
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      return date_dt.toLocaleDateString("de-DE", options);
    },
  },
  data: () => ({
    transactions: [],
    portfolio: [],
    username: "",
    date: new Date(Date.now()).toISOString().substr(0, 10),
    menu: false,
  }),
  methods: {
    async get_all_transactions() {
      let { data } = await this.$api.transaction.get_all_admin({
        user_id: this.$route.params.user_id,
        date: this.date,
      });
      this.transactions = data.transactions;
      this.portfolio = data.portfolio;
      this.username = data.username;
    },
  },
  watch: {
    async date() {
      this.get_all_transactions();
    },
  },
};
</script>
