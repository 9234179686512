var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"min-height":"100%","min-width":"100%","color":"grey lighten-2","tile":""}},[_c('v-card-title',[_vm._v("Nutzer")]),_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.employees),function(employee,e){return _c('v-col',{key:e,staticClass:"my-2",attrs:{"cols":"12","sm":"6"}},[_c('v-card',{staticClass:"mx-5",attrs:{"tile":"","height":"100%"}},[_c('v-card-title',{class:employee.inactive ? 'grey--text' : '',staticStyle:{"width":"100%","white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis"}},[_vm._v(_vm._s(employee.name)+" "+_vm._s(employee.inactive ? "(inaktiv)" : "")+" ")]),_c('v-card-text',_vm._l((['Admin', 'Index', 'Mitarbeiter']),function(role,r){return _c('v-chip',{key:r,staticClass:"mr-1",attrs:{"disabled":employee.inactive,"outlined":employee.roles.includes(role) ? false : true,"color":"primary"},on:{"click":function($event){return _vm.change_roles(employee.user_id, employee.roles, role)}}},[_vm._v(" "+_vm._s(role)+" "+_vm._s(employee.role)+" ")])}),1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.change_status(employee.user_id, !employee.inactive)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(employee.inactive ? "mdi-account-plus" : "mdi-account-minus")+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(employee.inactive ? "Account wieder aktivieren." : "Account deaktivieren."))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push({
                    name: 'AdminEmployeeView',
                    params: { user_id: employee.user_id },
                  })}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-text-box-search-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Portfolio und Transaktionshistorie ansehen")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.user_id = employee.user_id;
                  _vm.dialog_change = true;
                  _vm.changed_value = '';}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-rename-box")])],1)]}}],null,true)},[_c('span',[_vm._v("Nutzerdaten ändern")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.delete_employee(employee.user_id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Mitarbeiter löschen")])])],1)],1)],1)}),1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"success ma-5",attrs:{"fab":""},on:{"click":function($event){return _vm.open_dialog()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Neuen Nutzer anlegen")])]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Email-Adresse","required":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('v-text-field',{attrs:{"label":"Name","required":""},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('v-text-field',{attrs:{"label":"Passwort","type":"password","required":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"success","text":""},on:{"click":function($event){return _vm.create_employee()}}},[_vm._v(" Speichern ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.dialog_change),callback:function ($$v) {_vm.dialog_change=$$v},expression:"dialog_change"}},[_c('v-card',{staticClass:"pt-5"},[_c('v-card-text',[_c('v-select',{attrs:{"items":_vm.to_change_options,"item-text":"text","item-value":"value"},model:{value:(_vm.to_change),callback:function ($$v) {_vm.to_change=$$v},expression:"to_change"}}),_c('v-text-field',{attrs:{"label":_vm.to_change_options.filter(function (a) { return a.value === _vm.to_change; })[0].text +
            ' zu',"type":_vm.to_change === 'password' ? 'password' : '',"required":""},model:{value:(_vm.changed_value),callback:function ($$v) {_vm.changed_value=$$v},expression:"changed_value"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"success","text":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.change_employee()},"click":function($event){return _vm.change_employee()}}},[_vm._v(" Speichern ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }