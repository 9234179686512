import connection from "./connection";

const resource = "/api/article_check";
export default {
  get_options() {
    return connection.get(`${resource}/options`);
  },
  get_stock_name(isin) {
    return connection.get(`${resource}/stock_name/` + isin);
  },
  get_market_cap(data) {
    return connection.get(`${resource}/market_cap`, { params: data });
  },
  perform_audit(data) {
    return connection.get(`${resource}/perform_audit`, { params: data });
  },
  save_audit(data) {
    return connection.post(`${resource}/audit`, data);
  },
  get_audit(id) {
    return connection.get(`${resource}/audit/` + id);
  },
  get_audits(payload) {
    return connection.get(`${resource}/audit?page=${payload.page}`);
  },
  delete_audit(id) {
    return connection.delete(`${resource}/audit/` + id);
  },
};
