import connection from "./connection";

const resource = "/api/trading_account";
export default {
    get_all() {
        return connection.get(`${resource}`)
    },
    create(payload) {
        return connection.post(`${resource}`, payload)
    },
    delete(payload) {
        return connection.delete(`${resource}/` + payload.trading_account_id)
    },
}