import connection from "./connection";

const resource = "/api/transaction";
export default {
  get_options() {
    return connection.get(`${resource}/options`);
  },
  get_all() {
    return connection.get(`${resource}`);
  },
  get_all_admin(payload) {
    return connection.get(
      `${resource}/admin?user_id=${payload.user_id}&date=${payload.date}`
    );
  },
  get_all_portfolios() {
    return connection.get(`${resource}/portfolios`);
  },
  download_all_portfolios() {
    return connection.get(`${resource}/portfolios/download`);
  },
  get_isin_employee_holdings(isins) {
    return connection.get(`${resource}/get_isin_employee_holdings?` + isins);
  },
  create(payload) {
    return connection.post(`${resource}/create`, payload);
  },
  delete(payload) {
    return connection.delete(`${resource}/` + payload.transaction_id);
  },
  close_position(payload) {
    return connection.post(`${resource}/close_position`, payload);
  },
};
