import colors from 'vuetify/lib/util/colors'

export default {
    primary: {
        base: '#e0011b',
        darken1: colors.purple.darken2,
    },
    secondary: colors.indigo,
    // All keys will generate theme styles,
    // Here we add a custom `tertiary` color
    tertiary: colors.pink.base,
}