export default {
    namespaced: true,
    state: {
        snackbarData: null
    },
    getters: {
    },
    mutations: {
        setSnackbarData(state, payload) {
            state.snackbarData = payload
        }
    },
    actions: {
        setSnackbarData({ commit }, payload) {
            commit("setSnackbarData", payload)
        }
    }
}