<template>
  <v-card min-height="100%" min-width="100%" color="grey lighten-2" tile>
    <v-card-title>
      Aktuelle Positionen der Traderfox-Mitarbeiter anzeigen
    </v-card-title>
    <v-card-text>
      <div v-if="after_request">
        <p>Ausgewählte ISINs:
        <ul>
          <li v-for="(isin, i) in isins" :key="i">{{isin}}</li>
        </ul>
        </p>
        <div v-if="holdings.length > 0">
          Alle aktuell von TraderFox-Mitarbeitern gehaltene Positionen mit den
          ausgewählten ISINs oder davon abgeleitete Derivate:
          <v-simple-table dense class="mt-5">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Mitarbeiter</th>
                  <th class="text-left">Aktie</th>
                  <th class="text-left">Instrument</th>
                  <th class="text-left">Long / Short</th>
                  <th class="text-left">Letzte Transaktion</th>
                  <th class="text-left">
                    Transaktionstyp der letzten Transaktion
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(position, i) in holdings" :key="i">
                  <td>{{ position.name }}</td>
                  <td>{{ position.stock_name }}</td>
                  <td>{{ position.instrument }}</td>
                  <td>{{ position.direction }}</td>
                  <td>{{ position.execution_time }}</td>
                  <td>{{ position.transaction_type }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <div v-if="holdings.length === 0">
          Aktuell werden von TraderFox-Mitarbeitern keine Positionen mit den
          ausgewählten ISINs oder davon abgeleitete Derivate gehalten.
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    isins: [],
    holdings: [],
    after_request: false,
  }),
  created() {
    this.get_isin_employee_holdings();
  },
  methods: {
    async get_isin_employee_holdings() {
      this.holdings = [];
      this.isin_looked_up = null;
      if (this.isin !== "") {
        let { data } = await this.$api.transaction.get_isin_employee_holdings(
          this.$route.params.isins
        );
        this.holdings = data.holdings;
        this.isins = data.isins;
        this.after_request = true;
      }
    },
  },
};
</script>
