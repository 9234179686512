import Vue from "vue";
import Router from "vue-router";
import Login from "@/views/Login.vue";
import Dashboard from "@/views/Dashboard.vue";
import AdminPanel from "@/views/AdminPanel.vue";
import AdminEmployeeView from "@/views/AdminEmployeeView.vue";
import TransactionPanel from "@/views/TransactionPanel.vue";
import TransactionPanelPrint from "@/views/TransactionPanelPrint.vue";
import SessionPanel from "@/views/SessionPanel.vue";
import IndexPanel from "@/views/IndexPanel.vue";
import Blacklist from "@/views/Blacklist.vue";
import BlacklistViolations from "@/views/BlacklistViolations.vue";
import AllPortfolios from "@/views/AllPortfolios.vue";
import ISINLookup from "@/views/ISINLookup.vue";
import ISINSLookup from "@/views/ISINSLookup.vue";
import Accounts from "@/views/Accounts.vue";
import ArticleCheck from "@/views/ArticleCheck.vue";
import Rules from "@/views/Rules.vue";

import { store } from "./store/store";

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: "/isins/:isins",
      name: "ISINSLookup",
      component: ISINSLookup,
    },
    {
      path: "/",
      name: "ISINLookup",
      component: ISINLookup,
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: {
        requiresVisitor: true,
      },
    },
    {
      path: "/dashboard",
      name: "Dashboard",
      component: Dashboard,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/admin_panel",
      name: "AdminPanel",
      component: AdminPanel,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/rules",
      name: "Rules",
      component: Rules,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/admin_employee_view/:user_id",
      name: "AdminEmployeeView",
      component: AdminEmployeeView,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/transaction_panel",
      name: "TransactionPanel",
      component: TransactionPanel,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/transaction_panel/print",
      name: "TransactionPanelPrint",
      component: TransactionPanelPrint,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/sessions_panel",
      name: "SessionPanel",
      component: SessionPanel,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/index_panel",
      name: "IndexPanel",
      component: IndexPanel,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/blacklist",
      name: "Blacklist",
      component: Blacklist,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/blacklist_violations",
      name: "BlacklistViolations",
      component: BlacklistViolations,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/all_portfolios",
      name: "AllPortfolios",
      component: AllPortfolios,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/accounts",
      name: "Accounts",
      component: Accounts,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/article_check",
      name: "ArticleCheck",
      component: ArticleCheck,
      meta: {
        requiresAuth: true,
      },
    },
  ],
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters["user/logged_in"]) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.requiresVisitor)) {
    if (store.getters["user/logged_in"]) {
      next({
        path: "/dashboard",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;
