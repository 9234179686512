<template>
  <v-card min-height="100%" tile>
    <v-card-title>PDF-Report generieren</v-card-title>
    <v-card-text>
      <v-row align="center">
        <v-col cols="12" md="6">
          <v-card outlined>
            <v-date-picker
              width="100%"
              v-model="date"
              locale="de"
              type="month"
            ></v-date-picker>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <div align="center">
            <v-btn color="primary" depressed @click="show_pdf">
              <v-icon class="mr-2">mdi-printer</v-icon>Drucken
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  computed: {
    german_date() {
      var date_dt = new Date(
        Date.UTC(
          parseInt(this.date.split("-")[0]),
          parseInt(this.date.split("-")[1]),
          0,
          0,
          0,
          0
        )
      );
      const options = {
        year: "numeric",
        month: "long",
      };
      return date_dt.toLocaleDateString("de-DE", options);
    },
  },
  data: () => ({
    transactions: [],
    date: new Date().toISOString().substr(0, 7),
  }),
  methods: {
    show_pdf() {
      let month = this.date.split("-")[1];
      let year = this.date.split("-")[0];
      window.open(
        `api/transaction/report?year=${year}&month=${month}&german_date=${this.german_date}`,
        "_blank"
      );
    },
  },
};
</script>
