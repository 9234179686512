import { api } from "./../../connections/api"

export default {
    namespaced: true,
    state: {
        logged_in: localStorage.getItem('logged_in') || null,
        role: (localStorage.getItem('role') || '').split(","),
    },
    getters: {
        logged_in(state) {
            return state.logged_in !== null
        },
        role(state) {
            return state.role
        }
    },
    mutations: {
        login(state, payload) {
            state.logged_in = true
            localStorage.setItem('logged_in', true)
            state.role = payload.role
            localStorage.setItem('role', payload.role)
        },
        logout(state) {
            state.logged_in = null
            localStorage.removeItem('logged_in')
            state.role = null
            localStorage.removeItem('role')
        },
    },
    actions: {
        async login({ commit }, payload) {
            let { data } = await api.user.login(payload)
            if (data.success) {
                commit("login", data.data)
            }
        },
        async logout({ commit }) {
            let { data } = await api.user.logout({})
            if (data.success) {
                commit("logout")
            }
        },
    }
}