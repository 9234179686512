<template>
  <v-card min-height="100%" min-width="100%" color="grey lighten-2" tile>
    <v-card-title>Blacklist</v-card-title>

    <v-expansion-panels v-model="panel" tile class="pa-5">
      <v-expansion-panel v-for="(item, i) in 1" :key="i">
        <v-expansion-panel-header>
          Neuen Eintrag zur Portfolio-Blacklist hinzufügen
          <template v-slot:actions>
            <v-icon data-cy="add_entry" color="success">
              mdi-plus
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                data-cy="isin"
                v-model="isin"
                label="ISIN"
                placeholder="US0378331005"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-select
                data-cy="restriction"
                v-model="restriction"
                :items="restrictions"
                label="Verbot"
              ></v-select>
            </v-col>

            <v-col cols="12" md="4">
              <v-menu
                data-cy="valid_until"
                v-model="menu_valid_until"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="valid_until"
                    label="Gültig bis einschließlich"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="valid_until"
                  locale="de"
                  @input="menu_valid_until = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-spacer></v-spacer>
            <v-btn
              data-cy="add_entry_click"
              @click="addBlacklistEntry"
              color="success"
              text
              >Hinzufügen</v-btn
            >
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-card class="ma-5" tile>
      <v-card-title>
        Portfolio-Blacklist
      </v-card-title>
      <v-simple-table dense v-if="blacklist_portfolio.length > 0">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">ISIN</th>
              <th class="text-left">Aktie</th>
              <th class="text-left">Verbot</th>
              <th class="text-left">Gültig bis</th>
              <th class="text-left">Löschen</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="b in blacklist_portfolio"
              :key="b.id"
              :class="b.effective ? '' : 'grey--text text--lighten-2'"
            >
              <td>{{ b.isin }}</td>
              <td>{{ b.name }}</td>
              <td>{{ b.restriction }}</td>
              <td>{{ b.valid_until }}</td>
              <td>
                <v-btn
                  @click="deleteBlacklistEntry(b.id)"
                  fab
                  small
                  text
                  color="error"
                  ><v-icon>mdi-delete</v-icon></v-btn
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-card-text v-else>
        Zum derzeitigen Zeitpunkt gibt es keinen Eintrag.
      </v-card-text>
    </v-card>

    <v-card class="ma-5" tile>
      <v-card-title>
        Aktuelle Index-Blacklist
      </v-card-title>
      <v-simple-table dense v-if="blacklist_index.length > 0">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">ISIN</th>
              <th class="text-left">Aktie</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="b in blacklist_index" :key="b.id">
              <td>{{ b.isin }}</td>
              <td>{{ b.stock_name }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-card-text v-else>
        Zum derzeitigen Zeitpunkt gibt es keinen Eintrag.
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
export default {
  created() {
    this.get_blacklist();
  },
  data: () => ({
    blacklist_portfolio: [],
    blacklist_index: [],
    restrictions: ["long", "short", "long und short"],
    panel: null,
    menu_valid_until: false,
    isin: "",
    restriction: null,
    valid_until: new Date().toISOString().substr(0, 10),
  }),
  methods: {
    async addBlacklistEntry() {
      let data = {
        isin: this.isin,
        valid_until: this.valid_until,
        restriction: this.restriction,
      };
      await this.$api.blacklist_portfolio.create(data);
      this.get_blacklist();
      this.isin = "";
      this.restriction = null;
      this.panel = null;
      this.isin = "";
    },
    async get_blacklist() {
      var { data } = await this.$api.blacklist.get_all_public();
      this.blacklist_index = data.blacklist;
      data = await this.$api.blacklist_portfolio.get_all();
      this.blacklist_portfolio = data.data.blacklist;
    },
    async deleteBlacklistEntry(id) {
      await this.$api.blacklist_portfolio.delete_one({ id: id });
      this.get_blacklist();
    },
  },
};
</script>

<style></style>
