import connection from "./connection";

const resource = "/api/article";
export default {
  get_all(payload) {
    return connection.get(`${resource}/${payload.page}`);
  },
  audit(payload) {
    return connection.post(`${resource}/audit`, payload);
  },
};
