<template>
  <v-card min-height="100%" min-width="100%" color="grey lighten-2" tile>
    <v-card-title>
      <span class="headline">Regeln</span>
      <v-spacer></v-spacer>
      <v-btn color="primary" fab text @click="print"
        ><v-icon>mdi-printer</v-icon></v-btn
      >
    </v-card-title>
    <v-card class="ma-5" tile>
      <v-card-text v-html="rules"></v-card-text>
    </v-card>
  </v-card>
</template>

<script>
export default {
  created() {
    this.get_rules();
  },
  data: () => ({
    rules: "",
  }),
  methods: {
    async get_rules() {
      let { data } = await this.$api.rules.get();
      this.rules = data.html;
    },
    async print() {
      window.open("/api/rules/print");
    },
  },
};
</script>
